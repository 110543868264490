import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CaseStudyPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1 style={{textAlign: 'center', paddingTop: 40}}>Case Studies</h1>
    <div className="page-section flex">
      <h2>WAYNE GRETZKY’S TORONTO</h2>
      <p>Playing host to Wayne’s personal collection of memorabilia, Wayne Gretzky’s Toronto is in fact three places in one. The upscale dining room has a unique décor which highlights Wayne’s greatest moments, where their guests can enjoy great food and great, friendly service. The winning sports bar boasts 37 HDTV’s showing all sports, all the time. The fabulous Oasis Rooftop Patio is as individual as the restaurant, and welcomes guests to relax under the sun and stars from May to October.
Libation Ltd. was tasked with elevating Wayne Gretzky’s drink program to better suit a new space, patrons and the globally changing bar scene. As a full service partner, Libation Consulting Ltd. completed a comprehensive assessment of the needs, strengths and weakness in order to craft a custom program suited to Wayne Gretzky’s. The team worked with the beverage menu and educated their staff, resulting in an overall experiential improvement.</p>
      <p>Libation Ltd. was able to leverage Wayne Gretzky’s pre-existing bar and service by optimizing its space and products in order to more efficiently serve its customers. The team also conducted education and taste testing sessions for different varieties of alcohol. In addition, a customized cocktail menu was crafted catering to Wayne Gretzky’s patrons.</p>
      <p>On top of implementing a new bar experience at Wayne Gretzky’s, Libation Ltd. provided on-site training and assistance post rollout. This ensured not only a successful launch but ongoing success.</p>
    </div>
    <div className="page-section flex">
      <h2>THE MINT AGENCY</h2>
      <p>Mint is a full-service agency specializing in digital, experiential and event marketing, creative design and PR. They are architects of innovative strategy and cutting-edge experiences with a track record that speaks for itself. The team is composed of Type-A personalities, meticulous workaholics, and nutty creative geniuses. In short, They are Mint.</p>
      <p>In an ongoing partnership, Libation Ltd. Consultants work with The Mint Agency to leverage creative cocktail programs at events in effort to help create buzz worthy and memorable moments for their clients. The Mint Agency collaborates with Libation Ltd. when they desire an added value to their cocktail program, whether that means interesting branded cocktails or a custom beverage menu tailored to the event. The team also successfully works with their various food and beverage venues to create holistic menus, pairing the cocktails perfectly with food offered at events.</p>
      <p>In collaboration with The Mint Agency, the team has created custom beverage menus tailored to brands including: Nestle, Google and YouTube.</p>
      <p>Our collaboration with the Mint Agency ensures that every event is impressive and unique for their valuable clientele.</p>
      <p></p>
      <p></p>
      </div>
      <div className="page-section flex">
        <h2>HENNESSY USA</h2>
        <p>Hennessy is imported and distributed in the U.S. by Moet Hennessy USA, Inc. and is produced by Jas Hennessy & Co., a subsidiary of LVMH Moet Hennessy Louis Vuitton S.A. Hennessy distills, ages and blends a full range of marques, including Hennessy V.S, Privilege V.S.O.P, Hennessy Black, X.O, Paradis and Richard Hennessy.</p>
        <p>Hennessy USA, challenged with bringing their roster of beverages to the forefront of the international cocktail community, hired Libation Consulting Ltd. in an ongoing ambassadorship. By developing strategic branding opportunities and placements in boutique and trendy venues, Libation Consulting Ltd. developed a significant cocktail strategy assisting them in achieving their goal.</p>
        <p>Libation Ltd. created a comprehensive list of artisanal cocktails using Hennessy’s roster of high-quality alcohols. By integrating Hennessy’s products, Libation Ltd. was able to create a range of cocktails specially crafted to suit their diverse consumer groups. This meant sourcing top-of-the-line ingredients to blend one-of-a-kind beverages that would leave a lasting impression with drinkers.</p>
        <p>The cocktail strategy also involved showcasing these cocktails at a number of high-profile events, in addition to placing them on menus everywhere. Working in collaboration with Hennessy’s team, Libation Ltd. helped develop accounts, establish relationships within the industry and drive awareness for their products in the world of cocktails.</p>
        </div>
        <div className="page-section flex">
          <h2>ROSEWOOD ESTATES</h2>
          <p>Rosewood Estates winery is a family owned and operated craft winery focused on producing premium vineyard-inspired wines and innovative meads, made from estate-produced grapes and honey. Since it’s opening in 2008, Rosewood has won over 95 medals in domestic and international wine and mead competitions.</p>
          <p>Libation Ltd. Consultants act as ongoing in-house mixologists for Rosewood Estates. The team is tasked with creating an artisanal cocktail program from their line of mead products. Available on their website, each cocktail is specially crafted to highlight the unique flavours of the mead.</p>
          <p>Libation Ltd. Consultants are also responsible for developing an ongoing strategy to help increase the brand’s profile. Working with the winery, Libation Consulting Ltd. is able to place their one-of-a-kind cocktails on menus and drink lists everywhere. The strategy developed by Libation Ltd. Consultants has lead to significant press, including features on media outlets including Breakfast Television, in cocktail competitions and in trade shows.</p>
          </div>
  </Layout>
)

export default CaseStudyPage
